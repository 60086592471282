import { app } from './firebase'
import { getDatabase, ref, remove, set, off } from "firebase/database"

const db = getDatabase(app)

class CoordsDataService {
    getAll() {
        return ref(db, 'coordenadas')
    }

    create(tutorial) {
        return set(ref(db, `coordenadas/${tutorial.uuid}`),
            tutorial
        )
    }

    off() {
        return off(ref(db, 'coordenadas'))
    }


    delete(key) {
        return remove(ref(db, `coordenadas/${key}`))
    }

}

export default new CoordsDataService()
